* {
	 box-sizing: border-box;
}
 body {
	 background-image: linear-gradient(to right, #034378, #2d4e68);
	 -webkit-font-smoothing: antialiased;
	 -moz-osx-font-smoothing: grayscale;
	 height: 100vh;
	 font-family: 'Open Sans', sans-serif;
}
 .space {
    color: rgb(61, 61, 61);
    text-decoration: none;
	width:50%;
	cursor: pointer;
}
@media only screen and (max-width: 600px) {
	.space {
		color: rgb(61, 61, 61);
		text-decoration: none;
		width:100%;
		cursor: pointer;
	}
  }
 h1 {
	 font-family: 'Open Sans', sans-serif;
}
 .row {
	 width:  100%;
	 margin-top: 80px;
}
 .srow {
	 width: 200%;
	 margin-top: 80px;
}
 .card {
	 float: left;
	 padding: 0 1.7rem;
	 width: 100%;
}
 .card .menu-content {
	 margin: 0;
	 padding: 0;
	 list-style-type: none;
}
 .card .menu-content::before, .card .menu-content::after {
	 content: '';
	 display: table;
}
 .card .menu-content::after {
	 clear: both;
}
 .card .menu-content li {
	 display: inline-block;
}
 .card .menu-content a {
	 color: #fff;
}
 .card .menu-content span {
	 position: absolute;
	 left: 50%;
	 top: 0;
	 font-size: 10px;
	 font-weight: 700;
	 font-family: 'Open Sans';
	 transform: translate(-50%, 0);
}
 .card .wrapper {
	 background-color: #fff;
	 min-height: 540px;
	 position: relative;
	 overflow: hidden;
     margin-bottom: 30px;
	 box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
}
 .card .wrapper:hover .data {
	 transform: translateY(0);
}
 .card .data {
	 position: absolute;
	 bottom: 0;
	 width: 100%;
	 transform: translateY(calc(70px + 1em));
	 transition: transform 0.3s;
}
 .card .data .content {
	 padding: 1em;
	 position: relative;
	 z-index: 1;
}
 .card .author {
	 font-size: 12px;
}
 .card .title {
	 margin-top: 10px;
	 margin-bottom: 10px;
}
 .card .text {
	 height: 70px;
	 margin: 0;
}
 .principal .wrapper {
	 background: 20% 1% / cover no-repeat;
}
.principal .header {
    color: #fff;
    padding: 1em;    
}
.principal .header .date {
    float: left;
    font-size: 14px;
    text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
}
 .principal .content {
	 background-color: #fff;
	 box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
}
 .principal .title a {
	 color: #333333;
}
 .principal .menu-content {
	 text-align: center;
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 z-index: -1;
	 transition: transform 0.3s;
	 transform: translateY(0);
}
 .principal .menu-content li {
	 width: 33.333333%;
	 float: left;
	 background-color: #77d7b9;
	 height: 60px;
	 position: relative;
}
 .principal .menu-content a {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
	 font-size: 24px;
}
 .principal .menu-content span {
	 top: -10px;
}

.second .header {
    color: #fff;
    padding: 1em;    
}
.second .header .date {
    float: left;
    font-size: 14px;
    text-shadow: 1px 1px 2px black, 0 0 1em black, 0 0 0.2em black;
}
 .second .content {
	 background-color: #fff;
	 box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
}
 .second .title a {
	 color: #333333;
}
 .second .menu-content {
	 text-align: center;
	 position: absolute;
	 top: 0;
	 left: 0;
	 width: 100%;
	 z-index: -1;
	 transition: transform 0.3s;
	 transform: translateY(0);
}
 .second .menu-content li {
	 width: 33.333333%;
	 float: left;
	 background-color: #77d7b9;
	 height: 60px;
	 position: relative;
}
 .second .menu-content a {
	 position: absolute;
	 top: 50%;
	 left: 50%;
	 transform: translate(-50%, -50%);
	 font-size: 24px;
}
 .second .menu-content span {
	 top: -10px;
}